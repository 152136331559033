import React from "react";
import { enquireScreen } from "enquire-js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import { PropTypes } from "prop-types";
import { Nav00DataSource, Footer10DataSource } from "./data.source";
import Nav0 from "./components/Nav0";
import Footer1 from "./components/Footer1";
import Banner from "./pages/banner/Banner.jsx";
import Loader from "./components/Loader.jsx";
import Error from "./components/Error.jsx";

import RankForm from "./pages/rank_prediction/RankForm.jsx";
import RankResult from "./pages/rank_prediction/RankResult.jsx";
import { getAllExams } from "./data/features/exams";
import { getAllFilters } from "./data/features/filters";
import ScrollToTop from "./ScrollToTop";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

ReactGA.initialize("UA-83305228-5");

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.sendPageView(this.context.router.history.location);
    this.context.router.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render() {
    return this.props.children;
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  getInitialData = () => {
    Promise.all([this.props.getAllExams(), this.props.getAllFilters()]);
  };

  componentDidMount() {
    if (this.props.loading) {
      this.getInitialData();
    }
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const { isMobile } = this.state;
    const { loading, error } = this.props;
    return error ? (
      <Error />
    ) : loading ? (
      <Loader />
    ) : (
      <Router>
        <ScrollToTop>
          <GAListener>
            <div className="templates-wrapper">
              <Nav0
                id="Nav0_0"
                key="Nav0_0"
                dataSource={Nav00DataSource}
                isMobile={isMobile}
              />
              <Switch>
                <Route exact path="/" component={Banner} />
                <Route path="/:short_name/result" component={RankResult} />
                <Route path="/:short_name" component={RankForm} />
              </Switch>
              <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={isMobile}
              />
            </div>
          </GAListener>
        </ScrollToTop>
      </Router>
    );
  }
}

function mapStateToProps({ exams, filters }) {
  if (exams.error || filters.error) {
    return { error: true };
  }
  if (exams.loading || filters.loading) {
    return { loading: true };
  }

  if (
    !exams.loading &&
    !filters.loading &&
    exams.data.length &&
    filters.data.length
  ) {
    return { loading: false };
  }

  return { loading: true };
}

export default connect(
  mapStateToProps,
  { getAllExams, getAllFilters }
)(App);
