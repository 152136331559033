import api from "../utils/api";

// =================================================================================================
// FILTER TYPES ===================================================================================
// =================================================================================================
const FILTERS_LOADING = "FILTERS_LOADING";
const FILTERS_SUCCESS = "FILTERS_SUCCESS";
const FILTERS_ERROR = "FILTERS_ERROR";

// =================================================================================================
// FILTER REDUCERS ================================================================================
// =================================================================================================
export default function reducer(state = { loading: true }, action) {
  switch (action.type) {
    case FILTERS_LOADING:
      return { ...state, loading: true };
    case FILTERS_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case FILTERS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
}

// =================================================================================================
// FILTER ACTIONS =================================================================================
// =================================================================================================
export const getAllFilters = () => async dispatch => {
  dispatch({ type: FILTERS_LOADING });
  try {
    const res = await api.get(api.endpoints.filters);
    dispatch({ type: FILTERS_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: FILTERS_ERROR, error: true });
  }
};
