import React from "react";
import logo from "./img/logo.png";
import { OutboundLink } from "react-ga";

export const Nav00DataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: logo,
    url: "/"
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        a: {
          children: "Engineering Exams",
          href: "https://www.entrancezone.com/engineering/"
        }
      },
      {
        name: "item1",
        a: {
          children: "Professional Courses-Admissions",
          href: "https://www.entrancezone.com/admissions/"
        }
      },
      {
        name: "item2",
        a: { children: "Jobs", href: "https://www.entrancezone.com/jobs/" }
      }
    ]
  },
  mobileMenu: { className: "header0-mobile-menu" }
};

export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "home-page",
    children: [
      {
        name: "block0",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          className: "logo",
          children: logo
        },
        content: {
          className: "slogan",
          children: "Exploring Careers: KG to PhD"
        }
      },
      {
        name: "block1",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "Most Popular Exams" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - JEE Main 2019"
                  to="https://www.entrancezone.com/engineering/jee-main-2019-national-testing-agency/">
                  JEE Main 2019
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - JEE Advanced 2019"
                  to="https://www.entrancezone.com/engineering/jee-advanced-2019/">
                  JEE Advanced 2019
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - BITSAT 2019"
                  to="https://www.entrancezone.com/engineering/bitsat-2019/">
                  BITSAT 2019
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - WBJEEE 2019"
                  to="https://www.entrancezone.com/engineering/wbjee-2019/">
                  WBJEEE 2019
                </OutboundLink>{" "}
              </p>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - Manipal OET 2019"
                  to="https://www.entrancezone.com/engineering/mu-oet-2019/">
                  Manipal OET 2019
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - SRMJEE 2019"
                  to="https://www.entrancezone.com/engineering/srmjeee-2019/">
                  SRMJEE 2019
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - VITEEE 2019"
                  to="https://www.entrancezone.com/engineering/viteee-2019/">
                  VITEEE 2019
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - Find More Here."
                  to="https://www.entrancezone.com/engineering/exams/">
                  Find More Here.
                </OutboundLink>{" "}
              </p>
            </span>
          )
        }
      },
      {
        name: "block2",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "Quick Links" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Exams Calender"
                  to="https://www.entrancezone.com/engineering/entrance-exam-dates/">
                  Exams Calender
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Board Time Table"
                  to="https://www.entrancezone.com/admissions/category/10th-12th-boards/">
                  Board Time Table
                </OutboundLink>{" "}
              </p>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Admit Card"
                  to="https://www.entrancezone.com/engineering/category/admit-card/">
                  Admit Card
                </OutboundLink>{" "}
              </p>{" "}
              <br />
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Privacy Policy"
                  to="https://www.entrancezone.com/engineering/privacy-policy/">
                  Privacy Policy
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - About Us"
                  to="https://www.entrancezone.com/engineering/about-us/">
                  FAQ-About Us
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Contact Us"
                  to="https://www.entrancezone.com/engineering/lets-talk/">
                  Contact Us
                </OutboundLink>{" "}
              </p>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Disclaimer"
                  to="https://www.entrancezone.com/engineering/disclaimer/">
                  Disclaimer
                </OutboundLink>{" "}
              </p>
            </span>
          )
        }
      },
      {
        name: "block3",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "Follow Us On" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Social Media - Facebook"
                  to="https://www.facebook.com/entrancezone/">
                  Facebook
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Social Media - LinkedIn"
                  to="https://www.linkedin.com/company/entrancezone.com/">
                  LinkedIn
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Social Media - Twitter"
                  to="https://twitter.com/entrancezone">
                  Twitter
                </OutboundLink>{" "}
              </p>
            </span>
          )
        }
      }
    ]
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <span>
        ©2019 by{" "}
        <a href="https://entrancezone.com">SCUBEN ECOMMERCE PRIVATE LIMITED</a>{" "}
        All Rights Reserved
      </span>
    )
  }
};
