import React, { PureComponent } from "react";
import { enquireScreen } from "enquire-js";
import { Card, Skeleton, Avatar, Row, Col, Button, Icon } from "antd";
import { Link } from "react-router-dom";
const { Meta } = Card;

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

class FormCard extends PureComponent {
  render() {
    const { item } = this.props;
    const { short_name, logo } = item;
    return (
      <Link to={`/${short_name}`}>
        <Card className="ant-card-forms" hoverable>
          <Skeleton loading={false} active>
            <Row
              type="flex"
              style={{ justifyContent: "space-between", alignItems: "center" }}>
              <Col>
                <Meta
                  avatar={
                    <Avatar
                      className="card__logo"
                      src={`https://storage.googleapis.com/ezap-prod${
                        logo.url
                      }`}
                    />
                  }
                  title={short_name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "65px"
                  }}
                />
              </Col>

              <Col>
                {isMobile ? (
                  <Icon type="caret-right" />
                ) : (
                  <Button type="primary">
                    Get Rank
                    <Icon type="caret-right" />
                  </Button>
                )}
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Link>
    );
  }
}

export default FormCard;
