import React from "react";
import "antd/dist/antd.css";
import { Tooltip, Icon, Divider } from "antd";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import { Form, Input, Select, Row, Col, Button, Card } from "antd";

import NumericInput from "../../components/NumericInput";
import { getPredictedRank } from "../../data/features/rank";
import Info from "../../components/Info";
const { Option } = Select;

class RegistrationForm extends React.Component {
  state = {
    confirmDirty: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { name, phone, email, registration_no, expected_mark } = values;
        const data = {
          exam_short_name: this.props.match.params.short_name,
          name,
          phone,
          email,
          registration_no,
          expected_mark
        };
        this.props.getPredictedRank(data, this.props.history);
        this.googleAnalyticsEvent();
      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  getMarksDetail = short_name => {
    const result = this.props.exams.data.filter(exam => {
      return exam.short_name === short_name;
    });
    return result[0];
  };

  googleAnalyticsEvent = () => {
    ReactGA.event({
      category: "Rank",
      action: "Check",
      label: this.props.match.params.short_name
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { short_name } = this.props.match.params;

    const { maximum_marks, minimum_marks } = this.getMarksDetail(short_name);

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 12 }
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };
    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "91"
    })(
      <Select style={{ width: 70 }}>
        <Option value="91">+91</Option>
      </Select>
    );

    return (
      <Row type="flex" justify="center">
        <Col xs={23}>
          <div className="u-center-text u-margin-bottom-small u-margin-top-medium">
            <h2 className="heading-secondary">
              {`Rank Predictor for ${short_name}`}
            </h2>
            <p className="lead">Enter Details below to predict your rank</p>
          </div>
          <Row type="flex" justify="center">
            <Col xs={24} lg={12}>
              <Card
                style={{
                  marginBottom: "60px",
                  padding: "10px",
                  backgroundColor: "#F4F5F7"
                }}
                bordered={true}>
                <br />
                <Form onSubmit={this.handleSubmit}>
                  <Form.Item {...formItemLayout} label="Name">
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Name!",
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Your Name" />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="E-mail">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          type: "email",
                          message: "The input is not valid E-mail!"
                        },
                        {
                          required: true,
                          message: "Please enter your E-mail!"
                        }
                      ]
                    })(<Input placeholder="Your Email Id" />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="Phone No.">
                    {getFieldDecorator("phone", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your phone number!"
                        }
                      ]
                    })(
                      <Input
                        addonBefore={prefixSelector}
                        style={{ width: "100%" }}
                        type="number"
                        step={1}
                        min={1000000000}
                        placeholder="Your Phone No."
                        maxLength={10}
                        max={9999999999}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <span>
                        Registration No.
                        <em style={{ color: "#999" }}>(optional)</em>
                      </span>
                    }>
                    {getFieldDecorator("registration_no", {
                      rules: [
                        {
                          required: false,
                          message: "Please enter your Registration no.!"
                        }
                      ]
                    })(<Input placeholder="Your Registration No." />)}
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <span>
                        Expected Marks{" "}
                        <em
                          style={{
                            color: "rgba(0,0,0,0.45)",
                            fontStyle: "normal"
                          }}>
                          <Tooltip title={`Maximum marks ${maximum_marks}`}>
                            <Icon
                              type="info-circle-o"
                              style={{ marginRight: 4 }}
                            />
                          </Tooltip>
                        </em>
                      </span>
                    }
                    extra={`Total Marks for ${short_name} Examination is ${maximum_marks}.`}>
                    {getFieldDecorator("expected_mark", {
                      rules: [
                        {
                          required: true,
                          message: "Please Enter your Expected Marks!"
                        }
                      ]
                    })(
                      <NumericInput
                        minimum_marks={minimum_marks}
                        maximum_marks={maximum_marks}
                      />
                    )}
                  </Form.Item>
                  <br />
                  <Form.Item {...tailFormItemLayout}>
                    <Row>
                      <Col xs={24} sm={21}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          style={{ width: "inherit" }}>
                          Predict My Rank
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={23} className="u-margin-bottom-small">
          <Divider />
        </Col>
        <Col xs={23} className="u-margin-bottom-large">
          <Info short_name={this.props.match.params.short_name} />
        </Col>
      </Row>
    );
  }
}

const WrappedRegistrationForm = Form.create()(RegistrationForm);

function mapStateToProps({ exams }) {
  return { exams };
}

export default connect(
  mapStateToProps,
  { getPredictedRank }
)(WrappedRegistrationForm);
