import React, { Component } from "react";
import FormList from "../../components/FormList";
import { Row, Col, Divider } from "antd";
import Info from "../../components/Info";

class Banner extends Component {
  render() {
    return (
      <React.Fragment>
        <header className="banner">
          <div className="banner__text-box">
            <h2 className="heading-primary">
              <span className="heading-primary--main">
                Rank Predictor - 2019
              </span>
            </h2>
          </div>
        </header>
        <Row justify="center" type="flex">
          <Col xs={22} className="u-margin-bottom-small">
            <FormList />
          </Col>
          <Col xs={22} className="u-margin-bottom-small">
            <Divider />
          </Col>
          <Col xs={22} className="u-margin-bottom-large">
            <Info short_name="JEE MAIN" />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default Banner;
