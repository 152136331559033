import api from "../utils/api";

// =================================================================================================
// EXAM TYPES ======================================================================================
// =================================================================================================
const EXAMS_LOADING = "EXAMS_LOADING";
const EXAMS_SUCCESS = "EXAMS_SUCCESS";
const EXAMS_ERROR = "EXAMS_ERROR";

// =================================================================================================
// EXAM REDUCERS ===================================================================================
// =================================================================================================
export default function reducer(state = { loading: true }, action) {
  switch (action.type) {
    case EXAMS_LOADING:
      return { ...state, loading: true };
    case EXAMS_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case EXAMS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
}

// =================================================================================================
// EXAM ACTIONS ====================================================================================
// =================================================================================================
export const getAllExams = () => async dispatch => {
  dispatch({ type: EXAMS_LOADING });
  try {
    const res = await api.get(api.endpoints.exams);
    dispatch({ type: EXAMS_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: EXAMS_ERROR, error: true });
  }
};
