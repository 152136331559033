import React from "react";
import { Card } from "antd";

const Info = ({ short_name }) => {
  return (
    <Card className="info" title={`${short_name} Rank Prediction`}>
      <p>
        Students appearing for the entrance exams can use the {`${short_name}`}{" "}
        rank predictor tool to predict the probable rank. The rank predictor is
        developed to help the students to get an idea about their possible or
        probable rank even before the declaration of the result.
      </p>{" "}
      <br />
      <p>
        Follow the step by step procedure given below to get the predicted rank
        in {`${short_name}`}.{" "}
      </p>
      <br />
      <h3>How to use {`${short_name}`} Rank Predictor 2019?</h3>
      <br />
      <p>
        <b>Step 1</b> - Register or Login: First enlist by entering your name,
        Phone number, Email ID to enrol. On the other hand you can sign in
        utilizing your FB record or Google account too.
      </p>
      <br />
      <p>
        <b>Step 2</b> - Details or Inputs required for the {`${short_name}`}{" "}
        2019 Rank Predictor/Rank Estimator.
      </p>
      <br />
      <p>
        Aspirants need to enter the following details. These details will help
        in analysing better {`${short_name}`} rank.
      </p>{" "}
      <ol>
        <li>Name</li>
        <li>Location</li>
        <li>Phone Number</li>
        <li>Email id</li>
        <li>{`${short_name}`} 2018 Marks (if appeared)</li>
        <li>Expected marks or score Class XII percentage — optional</li>
        <li>Select class XII Board from the drop-down — optional</li>
        <li>Enter class XII passing year</li>
        <li>Select your category</li>
      </ol>
      <br />
      After entering all the required details, click on "Predict Now" button to
      get the best report of your rank in {`${short_name}`} .
      <br />
      <br />
      <b>Calculation of the rank:</b> The {`${short_name}`} Rank Predictor 2019
      will show your possible or probable rank for {`${short_name}`} in view of
      the given sources of info. It must be noted that the rank is demonstrative
      and indicative only, whereas original rank announced by National Testing
      Agency organiser of {`${short_name}`} May totally differ from rank
      predicted here.
      <br />
      <br />
      <b>Bonus:</b> Entering your details will help us in giving you a better
      recommendation of colleges and give you access to college predictor on
      priority.
    </Card>
  );
};

export default Info;
