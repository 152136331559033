import React, { Component } from "react";
import { Col, Row, Divider } from "antd";
import { connect } from "react-redux";
import FormCard from "./FormCard";
import SelectorHandler from "./SelectorHandler";
import { Card, Skeleton } from "antd";
const { Meta } = Card;

class FormList extends Component {
  updatedExam = () => {
    const { exams, selectedStream, selectedCourse } = this.props;
    return exams.filter(
      exam =>
        exam.streams.includes(selectedStream) &&
        (selectedCourse ? exam.courses.includes(selectedCourse) : true)
    );
  };

  proxyCardDisplay = () => {
    return [0, 1, 2].map(item => {
      return (
        <Col xs={24} md={12} lg={8} key={item}>
          <Card className="ant-card-forms">
            <Skeleton loading={true} avatar active />
          </Card>
        </Col>
      );
    });
  };

  noDataCardDisplay = () => {
    return (
      <Col xs={24}>
        <Card
          className="ant-card-forms"
          style={{
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <Meta title="No Data Found. Please change your Filters" />
        </Card>
      </Col>
    );
  };

  examCard = updatedExamData => {
    if (this.props.searchLoader) {
      return this.proxyCardDisplay();
    }
    if (updatedExamData.length === 0) {
      return this.noDataCardDisplay();
    }
    return updatedExamData.map((item, index) => {
      return (
        <Col xs={24} md={12} lg={8} key={index}>
          <FormCard item={item} key={index} />
        </Col>
      );
    });
  };

  sentenceFramed = examCount => {
    const { selectedStream, selectedCourse } = this.props;
    var result = `All Available Rank Predictors For ${
      selectedStream ? selectedStream : ""
    } ${selectedCourse ? ` → ${selectedCourse}` : ""} ( ${examCount} ${
      examCount > 1 ? "Exams" : "Exam"
    } )`;
    return result;
  };

  render() {
    const updatedExamData = this.updatedExam();
    const examCount = updatedExamData.length;
    return (
      <div className="u-margin-top-small">
        <Row type="flex" align="bottom" justify="space-between">
          {/* <Col xs={24} md={12} style={{ marginBottom: "8px" }}>
            <h2>{this.sentenceFramed(examCount)}</h2>
          </Col> */}
          <Col xs={24} md={12}>
            <SelectorHandler />
          </Col>
        </Row>
        {/* <Divider /> */}
        {this.examCard(updatedExamData)}
      </div>
    );
  }
}

function mapStateToProps({ exams, user, search }) {
  const { selectedCourse, selectedStream } = user;
  const { searchLoader } = search;
  return { exams: exams.data, selectedCourse, selectedStream, searchLoader };
}
export default connect(mapStateToProps)(FormList);
