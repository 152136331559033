import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { enquireScreen } from "enquire-js";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

export default class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row className="exception" type="flex" justify="center" align="middle">
        <Col xs={22} md={16} lg={14}>
          <Row type="flex" justify="center">
            <Col xs={24}>
              <div className="imgBlock">
                <div
                  className="imgEle"
                  style={{
                    backgroundImage: `url("https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg")`
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={22} md={8} lg={10}>
          <Row type="flex" justify="center">
            <Col xs={24} align={isMobile ? "center" : "start"}>
              <div className="content">
                <h1 className>500</h1>
                <div className="desc">Seems like we are under maintenance</div>
                <div className="actions">
                  <a href="https://entrancezone.com">
                    <Button type="primary">Visit Main Website</Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
