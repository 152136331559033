// =================================================================================================
// SEARCH TYPES ===================================================================================
// =================================================================================================
const SEARCH_LOADING = "SEARCH_LOADING";
const SEARCH_UNLOADING = "SEARCH_UNLOADING";

// =================================================================================================
// SEARCH REDUCERS ================================================================================
// =================================================================================================
export default function reducer(state = { searchLoader: false }, action) {
  switch (action.type) {
    case SEARCH_LOADING:
      return { ...state, searchLoader: true };
    case SEARCH_UNLOADING:
      return { ...state, searchLoader: false };
    default:
      return { ...state };
  }
}

// =================================================================================================
// SEARCH ACTIONS =================================================================================
// =================================================================================================
export const setSearchLoading = () => dispatch => {
  dispatch({ type: SEARCH_LOADING });
};

export const setSearchUnLoading = () => dispatch => {
  dispatch({ type: SEARCH_UNLOADING });
};
