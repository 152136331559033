import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import ReactGA from "react-ga";

import Selector from "./Selector";
import { updateUserDetail } from "../data/user";
import { setSearchUnLoading, setSearchLoading } from "../data/features/search";

class SelectorHandler extends Component {
  state = {
    streams: [],
    courses: [],
    selectedStream: undefined,
    selectedCourse: undefined
  };

  constructFilterData = () => {
    const { filters, user } = this.props;
    const data = {};
    filters.forEach(element => {
      data[element.stream] = element.courses;
    });
    const streams = Object.keys(data) || [];
    const selectedStream = streams.length ? streams[0] : undefined;
    const courses = selectedStream ? data[selectedStream] : [];

    this.setState({
      data,
      streams,
      courses
    });

    const newUser = { ...user, selectedStream, selectedCourse: undefined };
    this.props.updateUserDetail(newUser);
    //this.handleDataCallback(selectedStream, undefined);
  };

  handleDataCallback = (selectedStream, selectedCourse) => {
    const { user } = this.props;
    const newUser = { ...user, selectedStream, selectedCourse };
    this.props.updateUserDetail(newUser);
    this.handleSearchButton();
    // this.props.dataUpdate({ selectedStream, selectedCourse });
  };

  handleGoogleAnalytics = (action, label) => {
    ReactGA.event({
      category: "Stream & Course",
      action,
      label
    });
  };

  handleStreamChange = value => {
    const courses = this.state.data[value];
    this.setState({ courses });
    this.handleDataCallback(value, undefined);
    this.handleGoogleAnalytics("Stream Changed", `Stream -> ${value} `);
  };

  handleCourseChange = value => {
    this.handleDataCallback(this.props.user.selectedStream, value);
    this.handleGoogleAnalytics(
      "Course Changed",
      `Stream -> ${this.state.selectedStream}, Course -> ${value}`
    );
  };

  componentDidMount() {
    if (this.props.filters.length) {
      this.constructFilterData();
    }
  }

  handleSearchButton = () => {
    this.props.setSearchLoading();
    setTimeout(() => {
      this.props.setSearchUnLoading();
    }, 500);
  };

  render() {
    const { streams, courses } = this.state;
    const { user } = this.props;
    const { selectedStream, selectedCourse } = user;
    return (
      <div>
        {/* <Row type="flex" gutter={16} justify="end">
          <Col xs={24} md={12} lg={8} style={{ marginBottom: "8px" }}>
            <Selector
              placeholder="Select a Stream"
              handleSelectedValues={this.handleStreamChange}
              inputArray={streams}
              selectedValues={selectedStream}
            />
          </Col>
          <Col xs={24} md={12} lg={8} style={{ marginBottom: "8px" }}>
            <Selector
              placeholder="Select a Course"
              handleSelectedValues={this.handleCourseChange}
              inputArray={courses}
              selectedValues={selectedCourse}
            />
          </Col>
        </Row> */}
      </div>
    );
  }
}

function mapStateToProps({ filters, user }) {
  return { filters: filters.data, user };
}

export default connect(
  mapStateToProps,
  { updateUserDetail, setSearchLoading, setSearchUnLoading }
)(SelectorHandler);
