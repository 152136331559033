import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";

import filters from "./features/filters";
import search from "./features/search";
import exams from "./features/exams";
import sponsoredForms from "./features/sponsoredForms";
import rank from "./features/rank";
import user from "./user";

const rootReducer = combineReducers({
  filters,
  exams,
  sponsoredForms,
  user,
  rank,
  search
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
